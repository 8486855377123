export default class {
  constructor() {
    this.modal = '.js-modal01';
    this.contents = '.js-modal01__contents';
    this.init();
  }
	init() {

    const modalTrigger = document.querySelector(this.modal);
    const showContents = document.querySelector(this.contents);

    if(modalTrigger){

      modalTrigger.addEventListener('click' , function(){

        if(modalTrigger.classList.contains('is-active')){

          modalTrigger.classList.remove('is-active');
          showContents.classList.remove('is-active');

        } else {

          modalTrigger.classList.add('is-active');
          showContents.classList.add('is-active');

        }

      });

      showContents.addEventListener('click' , function(e){

        showContents.classList.add('is-active');

      });

      //モーダルコンテンツ外がクリックされたとき
      document.addEventListener('click', function() {

        if(showContents.classList.contains('is-active')){

          showContents.classList.remove('is-active');

        }

      });

    }

	}
}